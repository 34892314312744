<template>
  <div class="page">
    <div class="pageFucView">
      <div class="pageFucView_left">
        <el-form style="display: flex" label-position="left">
          <el-form-item style="margin-top: 0">
            <el-input
                placeholder="请输入单据号或供应商或序列号或备注"
                clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="日期：" label-width="60px" style="margin-top: 0">
            <el-date-picker
                type="daterange"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-button type="primary" icon="el-icon-search">查询</el-button>
        </el-form>
      </div>
      <div class="pageFucView_right">
        <el-button class="button" type="primary" size="small">新增</el-button>
        <el-button class="button" size="small">打印</el-button>
        <el-button class="button" size="small">导出</el-button>
        <el-button class="button" size="small">删除</el-button>
        <el-dropdown size="small" class="button" style="margin-left: 10px">
          <el-button type="primary">
            审核<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>反审核</el-dropdown-item>
          </el-dropdown-menu></el-dropdown
        >
      </div>
    </div>
    <el-table ref="multipleTable">
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column label="操作"></el-table-column>
      <el-table-column label="单据日期"></el-table-column>
      <el-table-column label="单据编号"></el-table-column>
      <el-table-column label="供应商"></el-table-column>
      <el-table-column label="购货金额"></el-table-column>
      <el-table-column label="优惠后金额"></el-table-column>
      <el-table-column label="已退款"></el-table-column>
      <el-table-column label="退款状态"></el-table-column>
      <el-table-column label="数量"></el-table-column>
      <el-table-column label="制单人"></el-table-column>
      <el-table-column label="审核人"></el-table-column>
      <el-table-column label="备注"></el-table-column>
    </el-table>
    <div class="pageView">
      <el-pagination
          layout="prev, pager, next"
          :page-size="search['per-page']"
          :current-page.sync="search.page"
          :total="pageCount"
          @current-change="pageChangeHandle"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "returnPurchaseHistory",
  data() {
    return {
      multipleTable: [],
      pageCount:0,
      search:{
        "per-page":10,
        page:1
      }
    };
  },
}
</script>

<style scoped>
.page {
  background-color: #fff;
  padding: 20px;
}
.pageView {
  display: flex;
  justify-content: flex-end;
}
.pageFucView {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.pageFucView_right {
  display: flex;
}
.el-form-item {
  margin-bottom: 0px;
  margin-right: 10px;
}
</style>